import React, { useRef, useContext } from "react"
import { CartCtx, useContextState } from "@components/contexted"

import { withHtml } from "@components/logic"
import { Layout } from "@components/layout"
import { BlogHero, Recommendations, CommentSection } from "@components/blog"
import {
  Breadcrumbs,
  ResizedImage,
  AnimatedLink,
  Modal,
  AnimatedButton,
} from "@components/shared"
import { DirectionArrows } from "@components/theme"
import {
  post,
  post__wrapper,
  post__content__wrapper__container,
  post__content__wrapper,
  post__content__wrapper__text,
  post__wrapper__description,
  post__wrapper__description__tags,
  post__wrapper__divider,
  post__wrapper__divider__mirror,
  post__content__wrapper__container__date,
  post__content__wrapper__container__tags,
  images,
  images__image,
} from "./styles/post.module.scss"
import Divider from "../../components.refactored/standard/Home/icons/divider-dark.svg"
import dayjs from "dayjs"
const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}

const Tags = ({ tags }) => {
  return (
    <div className={post__content__wrapper__container__tags}>
      {tags.nodes.map(({ slug, name }, i) => {
        return (
          <AnimatedLink to={`/tags/${slug}/`} key={i}>
            {"#" + name}
          </AnimatedLink>
        )
      })}
    </div>
  )
}
const Title = withHtml("h1")
const Content = withHtml("article")
const linkModifier = ({ slug }) => `/blog/${slug}/`

const Post = ({ pageContext }) => {
  const { page, posts, nextPost, prevPost, products } = pageContext
  const { featuredImage, date, tags } = page

  return (
    <Layout {...page}>
      <div className={post}>
        <div className={post__wrapper}>
          <ResizedImage {...featuredImage.node} size={"1024w"} />
          <div className={post__wrapper__description}>
            <Title>{page.title}</Title>
          </div>
        </div>
        <div className={post__content__wrapper}>
          <div className={post__content__wrapper__container}>
            <div className={post__content__wrapper__container__date}>
              {dayjs(date).format("DD.MM.YYYY")}
            </div>
            <Tags tags={tags} />
          </div>
          <img src={Divider} className={post__wrapper__divider__mirror} />

          <div className={post__content__wrapper__text}>
            <Content>{page.content}</Content>
          </div>
          <img className={post__wrapper__divider} src={Divider} />
          <DirectionArrows
            prevItem={prevPost}
            nextItem={nextPost}
            linkModifier={linkModifier}
          />
          <div className={images}>
            <img
              className={images__image}
              src={nextPost.featuredImage.node.sourceUrl}
            />
            <img
              className={images__image}
              src={prevPost.featuredImage.node.sourceUrl}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Post
